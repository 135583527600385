'use client'

import { useEffect, useState } from "react"
import Image from "next/image"
import { ChevronLeftIcon, ChevronRightIcon, Cross1Icon } from "@radix-ui/react-icons"

import { Button } from "@/components/ui/button"
import { NAV_LINK_VARIANTS, NavLink } from "@/components/ui/nav-link"
import { getAssetUrl, LOGO, SPECIFICITY, TYPE } from "@/lib/assets"
import { cn } from "@/lib/utils"
import { NAV_LIST } from "@/components/layout/navigation/nav-constants"
import { usePathname } from "next/navigation"
import { DEMO_ROUTE } from "@/app/routes"


const DrawerHeader = ({
  closeHandler,
  navListOpen,
  closeNavList
}) => {
  const sign3Logo = getAssetUrl(SPECIFICITY.LOGO, TYPE.NONE, LOGO.purpeBlack)
  return (
    <div
      className="w-full h-[65px] box-border p-4 flex justify-between items-center border-b border-layoutBorder shrink-0"
    >
      {!navListOpen && <Image alt="Sign3 logo" src={sign3Logo} width={135} height={32} />}
      {!!navListOpen && <Button
        variant="outline"
        className={cn(
          "w-fit py-[10px] px-4 flex justify-center items-center gap-x-2 !border-layoutBorder shadow-smallBtnShadow",
          "text-sm leading-125 font-semibold border !border-inpBrdrNtrl"
        )}
        onClick={closeNavList}
      >
        <ChevronLeftIcon className="w-4 h-4 font-semibold" />
        <span>Back</span>
      </Button>}
      <Button  
        onClick={closeHandler}          
        className={cn(
          "w-10 h-10 p-0 box-border rounded-md border !border-inpBrdrNtrl flex justify-center items-center",
          "shadow-draweClose bg-white hover:bg-white hover:!text-txtP hover:opacity-70"
        )}
      >
        <Cross1Icon className="w-5 h-5 !text-tlg font-semibold !text-txtP" />
      </Button>
    </div>
  )
}

const DrawerFooter = () => {
  return (
    <div
      className="w-full h-fit flex flex-col gap-y-6 justify-start items-center shrink-0 px-6 pb-6"
    >
      <NavLink
        variant={NAV_LINK_VARIANTS.PRIMARY}
        href={DEMO_ROUTE}
        className={"shadow-smallBrand w-full box-border py-3 px-4"}
      >
        <span className="text-white text-base leading-125 font-medium">Book a Demo</span>
        <ChevronRightIcon
          className='text-white h-3 w-3 xl:h-4 xl:w-4'
        />
      </NavLink>
      <NavLink
        variant={NAV_LINK_VARIANTS.OUTLINE}
        href="https://dashboard.sign3labs.com/auth/login"
        passHref legacyBehavior
        className={"inline-flex"}
      >
        <a
          target="_blank"
          className={cn(
            "text-gray-700 text-base leading-125 font-semibold rounded-[8px] w-full",
            "px-3 py-3 shadow-smallBtnShadow border border-inpBrdrNtrl bg-white",
            "flex justify-center items-center"
          )}
        >Login</a>
      </NavLink>
    </div>
  )
}

const NavItem = ({
item,
className
}) => {
  const {label, href, icon} = item
  return (
    <NavLink
      className={cn(
        "w-full flex justify-start items-center py-4 border-b border-layoutBorder hover:opacity-80 gap-x-2",
        className
      )}
      href={href}
    >
      {!!icon && <div className="flex w-6 h-6 justify-center items-center shrink-0 rounded-[4px] bg-scndryHoverbg">
        <Image alt={`${label} nav icon`} src={icon} height={24} width={24} />
      </div>}
      <span className={cn(
        "whitespace-nowrap text-sm md:text-[calc(4px+1.601vw)] leading-115 font-medium !text-txtP truncate"
      )}>{label}</span>
    </NavLink>
  )
}

const NavList = ({
  list
}) => {
  const { items, label } = list
  return (
    <div className="w-full h-fit flex flex-col justify-start items-start">
      <div className={cn(
        "w-full py-4 pr-3 flex justify-start items-center gap-x-2 border-b border-layoutBorder",
        "text-txtS text-sm md:text-[calc(4px+1.601vw)] leading-125 font-medium"
      )}>
        {label}
      </div>
      <>
        {items.map((item, index) => <NavItem key={index} item={item} />)}
      </>
    </div>
  )
}


const NavListItem = ({
  item,
  className,
  setNavItemList
}) => {
  const {navItems, label} = item
  return (
    <div
      className={cn(
        "w-full flex justify-between items-center py-4 border-b border-layoutBorder hover:opacity-80",
        className
      )}
      onClick={() => setNavItemList(navItems)}
    >
      <span className={cn(
        "whitespace-nowrap text-sm md:text-[calc(4px+1.601vw)] leading-115 font-medium !text-txtP"
      )}>{label}</span>
      <ChevronRightIcon className='!text-txtP h-4 w-4' />
    </div>
  )
}

const DrawerContent = ({
  navItemList,
  setNavItemList
}) => {

  return (
    <div className="w-full box-border grow px-6 pb-2 flex flex-col justify-start items-start relative">
      {NAV_LIST.map((list, index) => {
        const { isHoverButton, isLink } = list
        if (isLink) {
          return (
            <NavItem key={index} item={list} />
          )
        }
        if (isHoverButton) {
          return (
            <NavListItem key={index} item={list} setNavItemList={setNavItemList} />
          )
        }
      })}
      {!!navItemList && <div
        className={cn(
          "w-full h-full absolute top-0 left-0 bottom-0 bg-white px-6 box-border overflow-hidden"
        )}
      >
        <div className="w-full h-full overflow-y-scroll flex flex-col justify-start items-start box-border gap-y-6">
          {navItemList.map((item, index) => <NavList key={index} list={item} />)}
        </div>
      </div>}
    </div>
  )
}

export const NavDrawer = ({
  closeHandler,
  isOpen
}) => {
  const pathName = usePathname()
  const [navItemList, setNavItemList] = useState(null)
  const closeNavList = () => {
    setNavItemList(null)
  }

  const closeDrawer = () => {
    setNavItemList(null)
    closeHandler()
  }

  useEffect(() => {
    if (navItemList) {
      setNavItemList(null)
    }
  }, [pathName])

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={cn(
        "h-[100vh] absolute w-screen top-0 right-0 bg-white z-[99] xl:hidden",
        "flex flex-col justify-start items-start"
      )}
    >
      <DrawerHeader closeHandler={closeDrawer} navListOpen={!!navItemList} closeNavList={closeNavList} />
      <div className="w-full grow flex flex-col justify-start items-start">
        <DrawerContent navItemList={navItemList} setNavItemList={setNavItemList} />
        <DrawerFooter />
      </div>
    </div>
  )
}
