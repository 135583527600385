'use client'

import { useEffect, useState } from "react"
import Image from "next/image"
import { usePathname } from "next/navigation"

import { cn } from "@/lib/utils"
import { NavLink } from "@/components/ui/nav-link"
import { NAV_LIST } from "@/components/layout/navigation/nav-constants"
import { WithLayoutBorders } from "@/components/layout/with-layout-borders"
import { ChevronDownIcon } from "@radix-ui/react-icons"

const isNavItemActive = (currentPathname, navRef, refsToMatch) => {
  if (!!navRef && currentPathname.includes(navRef)) {
    return true
  }
  let isActive = false
  if (Array.isArray(refsToMatch)) {
    for (let ref of refsToMatch) {
      if (currentPathname.includes(ref)) {
        isActive = true
        break
      }
    }
  }
  return isActive
}


const NavItem = ({
  label,
  icon,
  href,
  className,
  borderOnActive=true
}) => {
  return (
    <NavLink
      href={href}
      className={cn(
        "group/item text-sm leading-115 font-medium !text-txtP px-4 h-full flex justify-center items-center box-border",
        borderOnActive && "border-b-[2px] border-transparent hover:border-b-[2px] hover:border-b-actnBrdr", "hover:opacity-1",
        className
      )}
    >
      {!!icon && <div className="flex w-6 h-6 justify-center items-center shrink-0">
        <Image alt={`${label} nav icon`} src={icon} height={24} width={24} />
      </div>}
      <span className={cn(
        "whitespace-nowrap group-hover/item:!text-txtAction", !!icon && "grow truncate",
      )}>{label}</span>
    </NavLink>
  )
}


const HoverNavList = ({
  navList,
  className
}) => {
  const {label, items } = navList
  return (
    <div
      className={cn(
        "w-[180px] flex flex-col justify-start items-start",
        className
      )}
    >
      {!!label && <div
        className="w-full flex justify-start items-center text-txtS py-3 pr-3 text-base font-medium leading-125"
      >{label}</div>}
      <div
        className="w-full flex justify-start items-start flex-wrap  gap-x-10"
      >
        {items.map((item, index) => <NavItem
          key={index}
          label={item.label} href={item.href} icon={item.icon} borderOnActive={false}
          className={"w-[180px] flex justify-start items-center gap-x-2 py-3 pl-0 pr-3"}
        />)}
      </div>
    </div>
  )
}

const NavHoverPanel = ({
  navItems
}) => {
  if (!navItems || !Array.isArray(navItems)) {
    return null
  }
  return (
    <div
      className={cn(
        "w-[670px] box-border h-fit flex justify-start items-start gap-x-10"
      )}
    >
      <HoverNavList navList={navItems[0]} className={"w-[408px]"}/>
      <div className="w-[1px] box-border bg-layoutBorder self-stretch"></div>
      {!!navItems && navItems.length > 1 && <HoverNavList navList={navItems[1]}/>}
    </div>
  )
}

const NavHoverWithBorders = WithLayoutBorders(NavHoverPanel)

const NavPanelWithBackDrop = ({
  hidePanelHandler,
  showPanel,
  navItems
}) => {
  return (
    <div
      className={cn(
        "absolute w-screen top-[64px] h-[calc(100vh-64px)] left-0 bg-backdrop z-50 flex",
        "flex-col justify-start items-start",
        !!showPanel && "flex",
        !showPanel && "hidden"
      )}
    >
      <NavHoverWithBorders
        className={cn(
          "bg-white border-t-0"
        )}
        topBorder
        innerContainerClass="xl:py-10"
        navItems={navItems}
      />
      <div className="flex-grow w-full" onMouseEnter={hidePanelHandler}></div>
    </div>
  )
}

const NavHoverableButton = ({
  label,
  navItems,
  isActiveRefs
}) => {
  const pathName = usePathname()
  const [showPanel, setShowPanel] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const showNavPavelHandler = () => {
    if (!showPanel) {
      setShowPanel(true)
    }
  }
  const hideNavPanelHandler = () => {
    if (showPanel) {
      setShowPanel(false)
    }
  }

  useEffect(() => {
    const active = isNavItemActive(pathName, null, isActiveRefs)
    setIsActive(active)
    setShowPanel(false)
  }, [pathName])

  return (
    <div
      className="group h-full"
      onMouseEnter={showNavPavelHandler}
      onMouseLeave={hideNavPanelHandler}
    >
      <div
        className={cn(
          "h-full box-border flex px-4 justify-center items-center gap-x-1 cursor-pointer text-sm leading-115 font-medium !text-txtP",
          "border-b-[2px] border-transparent",
          "hover:!text-txtAction hover:relative hover:border-b-[2px] hover:border-b-actnBrdr",
          "group-hover:border-b-[2px] group-hover:border-b-actnBrdr", "group-hover:!text-txtAction group-hover:relative"
        )}
      >
        <span>{label}</span>
        <ChevronDownIcon className="w-4 h-4 group-hover:rotate-180 transition-transform duration-200 ease-in-out" />
      </div>
      <NavPanelWithBackDrop
        showPanel={showPanel} hidePanelHandler={hideNavPanelHandler}
        navItems={navItems}
      />
    </div>
  )
}



export const NavMenue = () => {
  return (
    <nav className="hidden xl:flex justify-start items-center h-full box-border">
      {NAV_LIST.map((listItem, index) => {
        const { label, isLink, href, isHoverButton, isActiveRefs, navItems } = listItem
        if (isLink) {
          return (<NavItem
              key={index}
              label={label}
              href={href}
              isActiveRefs={isActiveRefs}
            />)
        }
        if (isHoverButton) {
          return (<NavHoverableButton
            key={index}
            label={label}
            navItems={navItems}
            isActiveRefs={isActiveRefs}
          />)
        }
      })}
    </nav>
  )
}