'use client'

import { motion, useInView } from 'framer-motion'
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';

export const LottiePlayer = ({
  url,
  className,
  addZTranslation
}) => {
  const [dotLottie, setDotLottie] = useState(null);
  const ref = useRef()

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  const inView = useInView(ref, {
    amount: 0.5
  })

  useEffect(() => {
    if (!inView && dotLottie) {
      dotLottie.pause()
    } else if (inView && dotLottie) {
      dotLottie.play()
    }
  }, [inView, dotLottie])

  return (
    <motion.div
      ref={ref}
      className={cn(
        'absolute inset-0 w-full h-full rounded-lg', className
      )}
      style={addZTranslation ? {
        transform: 'translateZ(30px)'
      } : {}}
    >
      <DotLottieReact
        className="bg-transparent w-full h-full"
        src={url}
        loop
        autoplay
        dotLottieRefCallback={dotLottieRefCallback}
      />
    </motion.div>
  )
}