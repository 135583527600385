import {
  ABOUT_US_ROUTE, ACCOUNT_TAKEOVER_ROUTE, BONUS_ABUSE_ROUTE, CREDIT_UNDERWRITING_ROUTE,
  DEVELOPERS_ROUTE, DEVICE_INTL_ROUTE, DIGITAL_FOOTPRINT_ROUTE, IDENTITY_FRAUD_ROUTE,
  ONBOARDING_ROUTE, RESOURCES_ROUTE, USER_PROSPECTING_ROUTE
} from "@/app/routes";
import { getAssetUrl, ICONS, SPECIFICITY, TYPE } from "@/lib/assets";

export const NAV_LIST = [
  {
    label: "Solutions",
    isLink: false,    
    isHoverButton: true,
    isActiveRefs: ['/solutions', '/products'],
    navItems: [
      {
        label: "By Usecases",
        items: [
          {
            label: "Digital Onboarding",
            href: ONBOARDING_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.onboardingFilled)
          },
          {
            label: "Identity Fraud",
            href: IDENTITY_FRAUD_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.identityFilled)
          },
          {
            label: "Bonus Abuse",
            href: BONUS_ABUSE_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.bonusFilled)
          },
          {
            label: "Credit Underwriting",
            href: CREDIT_UNDERWRITING_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.underwritingFilled)
          },
          {
            label: "Account Takeover",
            href: ACCOUNT_TAKEOVER_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.takeoverFilled)
          },
          {
            label: "User Prospecting",
            href: USER_PROSPECTING_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.usrProspectFilled)
          }
        ]
      },
      {
        label: "By Products",
        items: [
          {
            label: "Digital Footprint",
            href: DIGITAL_FOOTPRINT_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.dfFilled)
          },
          {
            label: "Device Intelligence",
            href: DEVICE_INTL_ROUTE,
            icon: getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.diFilled)
          }
        ]
      }
    ]
  },
  {
    label: "Articles",
    isLink: true,
    href: RESOURCES_ROUTE
  },
  {
    label: "About us",
    isLink: true,
    href: ABOUT_US_ROUTE
  }
]