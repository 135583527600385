'use client'

import { useEffect, useState } from "react"
import Image from "next/image"
import { usePathname } from "next/navigation";
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { useScrollTop } from "@wojtekmaj/react-hooks";

import { cn } from "@/lib/utils"
import { NAV_LINK_VARIANTS, NavLink } from "@/components/ui/nav-link"
import { getAssetUrl, ICONS, LOGO, SPECIFICITY, TYPE } from "@/lib/assets"
import { Button } from "@/components/ui/button"
import { NavMenue } from "@/components/layout/navigation/nav-menu";
import { NavDrawer } from "@/components/layout/navigation/nav-drawer";
import { DEMO_ROUTE } from "@/app/routes";

const Header = ({

}) => {
  const pathName = usePathname()
  const scrollTop = useScrollTop();

  const [isScrolled, setIsScrolled] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false)

  const sign3Logo = getAssetUrl(SPECIFICITY.LOGO, TYPE.NONE, LOGO.purpeBlack)
  const hamIcon = getAssetUrl(SPECIFICITY.NONE, TYPE.ICON, ICONS.hamIcon)

  const showNavModalHandler = () => {
    if (!showMobileNav) {
      setShowMobileNav(true)
    }
  }

  const hideNavModalHandler = () => {
    if (showMobileNav) {
      setShowMobileNav(false)
    }
  }

  useEffect(() => {
    if (!!scrollTop && scrollTop > 10) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [scrollTop])

  useEffect(() => {
    setShowMobileNav(false)
  }, [pathName])

  return (
    <div
        className={cn(
            "w-screen box-border sticky top-0 h-16 z-50 px-4 md:px-5 xl:px-0 flex justify-center items-center",
            isScrolled && "bg-headerbg backdrop-blur-lg",
            "xl:hover:!bg-white",
        )}
      >
        <div className={cn(
          "w-full shrink-0 xl:w-[1242px] mx-auto box-border h-16 md:px-5 flex justify-between items-center"
        )}>
          <nav className={cn("flex justify-start items-center gap-x-8 h-full box-border")}>
            <NavLink
              href="/"
              className={"hover:opacity-1"}
            >
              <Image
                className={""}
                alt="Sign3 logo"
                src={sign3Logo}
                width={135}
                height={32}
              />
            </NavLink>
            <NavMenue />
          </nav>
          <div className={cn("flex justify-end items-center gap-x-4 relative")}>
            <Button variant="ghost" className="w-10 h-10 p-0 xl:hidden" onClick={showNavModalHandler}>
              <Image
                alt="ham icon"
                src={hamIcon}
                height={40}
                width={40}
              />
            </Button>
            <div
              className={cn(
                "w-fit justify-start items-center gap-x-4 translate-x-[158px] hidden xl:flex",
                "transition-transform cubic-bezier(.6,.6,0,1) duration-500",
                isScrolled && "translate-x-0"
              )}
            >
              <a
                target="_blank"
                href="https://dashboard.sign3labs.com/"
                className={cn(
                  "flex text-sm leading-115 font-medium rounded-[8px] px-4 py-3 shadow-smallBtnShadow",
                  "border border-inpBrdrNtrl bg-white xl:text-gray-700", "hover:!text-txtAction hover:border-scndryHoverBrdr"
                )}
              >Login</a>
              <NavLink
                variant={NAV_LINK_VARIANTS.PRIMARY}
                className={cn(
                  "px-4 py-3 opacity-0 flex pointer-events-none rounded-[8px]",
                  "transition-opacity cubic-bezier(.6,.6,0,1) duration-500",
                  isScrolled && "opacity-1 pointer-events-auto"
                )}
                href={DEMO_ROUTE}
              >
                <span className="whitespace-nowrap text-sm leading-115 font-medium">Book a Demo</span>
                <ChevronRightIcon color="#fff" width={12} height={12} />
              </NavLink>
            </div>
            
          </div>
        </div>
        <NavDrawer closeHandler={hideNavModalHandler} isOpen={showMobileNav} />
      </div>
  )
}

export default Header
