export const DEMO_ROUTE = '/book-a-demo'
export const ONBOARDING_ROUTE = '/solutions/onboarding'
export const IDENTITY_FRAUD_ROUTE = '/solutions/identity-fraud'
export const BONUS_ABUSE_ROUTE = '/solutions/bonus-abuse'
export const CREDIT_UNDERWRITING_ROUTE = '/solutions/credit-underwriting'
export const ACCOUNT_TAKEOVER_ROUTE = '/solutions/account-takeover'
export const USER_PROSPECTING_ROUTE = '/solutions/user-prospecting'
export const DIGITAL_FOOTPRINT_ROUTE = '/products/digital-footprint'
export const DEVICE_INTL_ROUTE = '/products/device-intelligence'
export const RESOURCES_ROUTE = '/resources'
export const DEVELOPERS_ROUTE = '/developers-section'
export const ABOUT_US_ROUTE = '/about-us'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'
